import { useCallback, useEffect, useState } from "react";
import Paginator from "../../../ui/pagination";
import { IPaginatedRequest } from "../../../../interfaces";
import { BillingStatus, allBillingsAtom } from "../Billing";
import { useDebouncedValue } from "@mantine/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import { getPaginatedTreasury } from "../../../../actions/billings";
import Preloader from "../../../ui/preloader/Preloader";
import { DateTime } from "luxon";
import { convertPrice } from "../../tables/gestao/UpdateGestao";
import { renderPaymentStatus } from "../single-billing/SingleBillingTable";
import { useAtom } from "jotai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { PriceBreakdown } from "./PriceBreakdown";


export const totalAmount = (lines: any[]) => {
  let total = 0;
  lines?.forEach((l: any) => {
    total += l.price_total;
  });
  return total;
};

export const totalProducts = (lines: any[]) => {
  let total = 0;
  lines?.forEach((l: any) => {
    total += l.product_uom_qty;
  });
  return total;
};

export default function TreasuryTable() {
  // const [paginatedData, setPaginatedData] = useState<number>();
  // const [limit, setLimit] = useState<number>(50);

  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    status: BillingStatus.ALL_STATUSES,
    vendorName: "All vendors",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
  // const [selectedBillings, setSelectedBillings] = useAtom(selectedBillingsAtom);
  const [allBillings, setAllBillings] = useAtom(allBillingsAtom);
  const [search, setSearch] = useState<string>('');
  const [openTreasury, setOpenTreasury] = useState<string>();
  const [modal, setModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});

  const { isLoading: loadBillings, data: billings } = useQuery(
    // `/billings/paginatedShipments?${queryString.stringify(debouncedConfig)}`,
    `/payouts/treasury?${queryString.stringify(debouncedConfig)}`,
    () => getPaginatedTreasury(debouncedConfig),
    { refetchOnWindowFocus: false }
  );

  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  /*
  const handleSelectOne = useCallback(
    (item: any) => () => {
      if (item.payment_state === 'not_paid') {
        setSelectedBillings((prev:any) => {
          if (prev.find((x:any) => x._id === item._id)) {
            return prev.filter((x:any) => x._id !== item._id);
          }
          return [
            ...prev, 
            {
              _id: item._id,
              seller: item.seller.name,
              billing: item.billing_name,
              platform: item.order?.zeoosName,
              order: item.related_sale_order,
              products: totalProducts(item.billing_lines),
              amount: totalAmount(item.billing_lines),
              date: item.date_creation
            }
          ];
        });
      }        
    },
    [setSelectedBillings]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedBillings((prev) =>
      !prev.length 
        ? billings?.data
          .filter((b: any) => b.payment_state === 'not_paid')
          .map((item:any) => ({
            _id: item._id,
            seller: item.seller.name,
            billing: item.billing_name,
            platform: item.order?.zeoosName,
            order: item.related_sale_order,
            products: totalProducts(item.billing_lines),
            amount: totalAmount(item.billing_lines),
            date: item.date_creation
          })) 
        : []
    )
  }, [billings?.data, setSelectedBillings]);
  */

  useEffect(() => {
    setAllBillings(billings?.total);
  // eslint-disable-next-line
  }, [billings]);

  const handleAccordClick = (id: any) => {
    if (openTreasury === id) setOpenTreasury("");
    if (openTreasury !== id) setOpenTreasury(id);
  };

  const maketplacePaymentState = (item: any) => {
    const payments = item.order?.marketplacePayment
    let state = 'not_paid';
    payments?.forEach((pay:any) => {
      state = pay.state;
    })

    if (item.order?.refund_status) {
      state = item.order?.refund_status;
    }

    return state;
  }

  const isPayable = (item: any) => {
    return item.payment_state !== "not_paid" || maketplacePaymentState(item) !== "paid"
      ? false
      : true
  }

  if (loadBillings) {
    return <Preloader />;
  }

  return (
    <div className="table-main-cont">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="table-search-box">
            <form className="table-search-form ml2" onSubmit={(e) => {
              e.preventDefault();
              const form = e.target as HTMLFormElement;
              setCurrentConfig({
                ...currentConfig,
                search: form['search'].value
              });
            }}>
              <input
                className="table-search-input"
                name="search"
                placeholder="Search product"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <img
                className="table-search-image"
                src="/icons/search.svg"
                width="25px;"
                alt=""
              />
            </form>
          </div>
          <div className="table-main-title-text">{allBillings} Billings</div>
        </div>
        <div className="marketplace-pagination">
          <Paginator
            data={billings}
            // rowsPerPage={limit}
            // setPaginatedData={setPaginatedData}
            // setLimit={setLimit}
            // limit={limit}
            refetch={onChangePage as any}
          />
        </div>
      </div>
      <div className="table-border-bottom"></div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}>
          <thead>
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="ml3 mr3">
                {/* <img
												className="pointer"
												src={
													selectedBillings.length
														? "/icons/inventory-all-selected.svg"
														: "/icons/check-box.svg"
												}
												alt=""
												onClick={handleSelectAll}
											/> */}
              </th>
              <th className="treasury-id">Billing ID</th>
              <th className="treasury-order">Purchase<br />Order ID </th>
              <th className="treasury-seller">Seller</th>
              <th className="treasury-name">Plaform</th>
              <th className="treasury-name">Plaform Order</th>
              <th className="treasury-status">Plaform Status</th>
              <th className="treasury-country">Customer<br />Country</th>
              <th className="treasury-products">Items<br />Sold</th>
              <th className="treasury-amount">Purchase<br />Price Total</th>
              <th className="treasury-date">Date<br />Time</th>
              <th className="treasury-status">Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <div>
          <table className="table-results p0">
            <thead></thead>
            <tbody>
              {billings.data.map((item: any, index: number) => (
                <Accordion expanded={item._id === openTreasury} className="m0" key={index}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="width100 p0 m0div"
                  >
                    <tr className="table-result-box billing-text m0">
                      <td className="ml3 mr3"
                      // onClick={handleSelectOne(item)}
                      >
                        {/* <img
                                        className={`${item.payment_state!=='not_paid'?'disabled':'pointer'}`}
                                        src={
                                          !selectedBillings.find((x:any) => x._id === item._id)
                                            ? "/icons/check-box.svg"
                                            : "/icons/check-box-checked.svg"
                                        }
                                        alt=""
                                      /> */}
                      </td>
                      <td className="treasury-id bold">
                        <img className="mr4" alt=""
                          src={
                            item._id === openTreasury
                              ? "/icons/vendors-arrow-up.svg"
                              : "/icons/vendors-arrow-down.svg"
                          }
                          onClick={() => {
                            handleAccordClick(item._id);
                          }}
                        />
                        {item.billing_name}
                      </td>
                      <td className="treasury-order">
                        {item.shipment?.shipment_name}
                        {item.billing_name.indexOf('_DS_') !== -1
                          ? <img className="pl1" src="/icons/treasure-DS.svg" alt="" />
                          : <img className="pl1" src="/icons/treasure-FF.svg" alt="" />
                        }

                      </td>
                      <td className="treasury-seller">{item.seller.name}</td>
                      <td className="treasury-name">{item.order?.zeoosName}</td>
                      <td className="treasury-name">{item.order?.order_marketplace}</td>
                      <td className="treasury-name">
                        {
                          renderPaymentStatus(
                            maketplacePaymentState(item)
                          )
                        }
                      </td>
                      <td className="treasury-country">
                        <img
                          width="28px"
                          src={`/icons/${item.order?.customer?.country?.toLowerCase()}.svg`}
                          alt={item.order?.customer.country} />
                      </td>
                      <td className="treasury-products">
                        <img className="mr1" src="/icons/paybatch-product.svg" alt="" />
                        {totalProducts(item.billing_lines)}
                      </td>
                      <td className="treasury-amount bold">
                        {convertPrice(totalAmount(item.billing_lines))} €
                      </td>
                      <td className="treasury-date">
                        {DateTime.fromISO(item.date_creation).toFormat("dd/MM/yy HH:mm")}
                      </td>
                      <td className="treasury-status">
                        {renderPaymentStatus(
                          ["canceled","refunded"].includes(item.order?.status)
                          ? item.order?.status
                          : item.payment_state
                        )}
                      </td>
                    </tr>
                  </AccordionSummary>
                  <AccordionDetails className="width100 p0 m0div">
                    <table className="width100 p0 m0div billing-lines">
                      <thead>
                        <tr className="table-results-title width100 height40px">
                          <th className="product">SKU - Product</th>
                          <th className="items">Items sold</th>
                          <th className="price">Unit Purchase Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.billing_lines.map((line: any, index: number) => (
                          <tr className="table-results-title width100" key={index}>
                            <td className="product">{line.sku} - {line.name}</td>
                            <td className="items">{line.product_uom_qty}</td>
                            <td className="price pointer" onClick={(e) => {
                              setSelectedProduct({
                                ...line,
                                billingDate: item.date_creation,
                                pricingType: item.seller?.pricingType
                              });
                              setModal(true);
                            }}>
                              {convertPrice(line.price_unit)} €
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modal && (
        <PriceBreakdown
          modal={modal}
          setModal={setModal}
          product={selectedProduct}
        // productMarketplace={marketplace as IMarketplace}
        // vendors={vendors}
        />
      )}
    </div>
  );
}
