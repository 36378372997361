import { NavLink } from "react-router-dom";

export default function SyncNav() {
  return (
    <div className="table-value-search-cont mt2">
      <div className="table-title-nav-cont">
        <NavLink
          to="/jobs-panel"
          className="table-tab-sync"
          activeClassName="table-tab-sync-active"
        >
          Jobs Panel
        </NavLink>
        <NavLink
          to="/queue"
          className="table-tab-sync"
          activeClassName="table-tab-sync-active"
        >
          Queue Monitor
        </NavLink>
        <NavLink
          to="/file-management"
          activeClassName="table-tab-sync-active"
          className="table-tab-sync"
        >
          File Management
        </NavLink>
        <NavLink
          to="/batch-list"
          activeClassName="table-tab-sync-active"
          className="table-tab-sync"
        >
          Export product
        </NavLink>
      </div>
    </div>
  );
}
